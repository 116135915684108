@import './../../styles/_mixings';

.Course {
  overflow: hidden;
  background-color: #fafbfc;

  .head {
    margin: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #dbe2e8;
    -webkit-box-shadow: 0px 12px 30px -9px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 12px 30px -9px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 12px 30px -9px rgba(0, 0, 0, 0.29);

    .course-title,
    .course-prog,
    .course-progress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 34px;
      text-align: left;
    }

    .course-prog {
      margin: 0;
      transition: min-height .3s ease-in-out;

      @include lg {
        justify-content: flex-end;
      }

      button {
        line-height: 2.5;
      }
    }

    .course-title {
      margin: 0;
      transition: min-height .3s ease-in-out;
      background-color: #fff;
      min-height: 192px;

      .navigate {
        text-transform: lowercase;
      }

      h4 {
        font-size: 36px;
        font-weight: 900;
      }
    }

    .course-progress {
      min-height: 48px;
      transition: height .3s ease-in-out;
      line-height: 1.33333em;
      padding-top: 0;
      padding-bottom: 0;
      background-color: #fafbfc;
      border-top: 1px solid #dbe2e8;
      position: relative;
      box-sizing: border-box;

      p {
        flex-grow: 1;
        font-size: 14px;
        line-height: 16px;
        color: #2e3d49;
      }
    }
  }

  .none {
    display: none;
  }

  .shrink-block {
    margin: 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #dbe2e8;
    z-index: 1000;
    -webkit-box-shadow: 0px 12px 30px -9px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 0px 12px 30px -9px rgba(0, 0, 0, 0.29);
    box-shadow: 0px 12px 30px -9px rgba(0, 0, 0, 0.29);

    .course-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 34px 10px 34px;
      text-align: left;

      margin: 0;
      transition: min-height .3s ease-in-out;
      background-color: #fff;
      min-height: 54px;

      .navigate {
        text-transform: lowercase;
      }

      h4 {
        font-size: 25px;
        font-weight: 300;
      }

      button {
        line-height: 2.5;
      }
    }
  }

  .courses {
    .unit-item {
      padding: 25px;
      width: 100%;

      .details {
        display: -webkit-box;
        height: 4.5em;
        line-height: 1.5em;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-size: 1rem;
        line-height: 1.7;
      }

      .unit-heading {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.33333em;
        margin-right: 10px;
      }
    }
  }
}
