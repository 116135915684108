@import './../../../styles/_mixings';

.Course {
    overflow: hidden;

    .root-div {
        box-sizing: border-box;
        max-width: 1024px;
        padding: 0 2rem;
        margin: 0 auto;
        margin-top: 5%;
        width: 100%;
        min-height: 50%;

        .add-unit {
            position: absolute;
            left: 47%;
            bottom: 20px;
        }
    }

    .course-head {
        // position: fixed;
        // left: 0;
        // top: 0;
        z-index: 1;
        transition: padding 0.15s linear;
        padding: 6% 3% 8% 3%;

        p {
            display: block;
        }

        .add-unit {
            margin-top: 20px;
            display: block;
        }

        .description {
            display: block;
        }
    }

    ol, li {
        list-style: none;
    }

    li + li {
        margin-top: 30px;
    }

    .smaller {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        transition: padding 0.25s linear;
        padding-top: 1%;
        padding-bottom: 1%;

        p, .add-unit, .description {
            display: none;
        }
    }
}