@import './../../styles/_mixings';

.Profile {
    @include headerMargin();
    overflow: hidden;
    margin-top: 10%;
    .info-block,
    .avatar-block {
        text-align: left;
        .heading {
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.4);
        }
        .top {
            display: flex;
            flex-direction: column;
            align-content: space-between;
            height: 200px;
            position: relative;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .editBtn {
                position: absolute;
                bottom: 15px;
                left: 0;
            }
        }
    }
    .avatar {
        width: 200px;
        height: 200px;
        border-radius: 3px;
        position: relative;
        border: 1px solid gray;
        img {
            opacity: 1;
            display: block;
            width: 100%;
            height: auto;
            transition: .5s ease;
            backface-visibility: hidden;
            border-radius: 2px;
        }
        .middle {
            transition: .5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%)
        }
        .text {
            background-color: #4CAF50;
            color: white;
            font-size: 16px;
            padding: 16px 32px;
            cursor: pointer;
        }
    }
    .avatar:hover img {
        opacity: 0.3;
    }
    .avatar:hover .middle {
        opacity: 1;
    }
}

.ReactCrop {
    display: block !important;
    max-height: 500px;
}