.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  text-align: center;
  user-select: none;
  position: relative;
  z-index: 500;
  flex: 0 0 auto;

  >.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 15px 30px 15px;

    li {
      display: inline-block;
      padding: 5px 10px;
    }
  }

  >.sub-footer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    .logo {
      margin: 20px 0px;

      .SVG {
        svg {
          height: 20px;
        }
      }
    }

    .copyright,
    .contact {
      width: 300px;
    }

    .copyright {
      >p {
        font-size: 12px;
      }
    }

    .contact {
      a {
        font-size: 12px;
      }
    }
  }
}