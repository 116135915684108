@import './../../styles/_mixings';

.Unit {
    overflow: hidden;
    position: fixed;
    width: 100%;

    .root-div {
        box-sizing: border-box;
        max-width: 1024px;
        padding: 0 2rem;
        margin: 0 auto;
        margin-top: 20%;
        width: 100%;
    }
    .drawer {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100vh;

        // * {
        //     color: #fff !important;
        // }

        .lesson-active {
            background: rgb(138, 137, 137);
        }

        div {
            white-space: normal;
        }
    }

    .course-head {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 25%;
        transition: height 0.15s linear;
    }

    ol, li {
        list-style: none;
    }

    li + li {
        margin-top: 30px;
    }

    .smaller {
        height: 50px;
        transition: height 0.25s linear;
    }

    .content {
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }
}