@import './../../styles/_mixings';

.Landing {
    overflow: hidden;
    @include headerMargin();

    .top-box {
        background-color: hsl(0, 0%, 7%);
        background-position: center top;
        background-size: cover;
        min-height: 461px;
        padding: 96px 16px 48px 16px;
        text-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        display: flex;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        h4 {
            height: 50px;
            line-height: 100%;
            font-weight: 600;
        }
        .subtitle {
            text-align: center;
            margin: 68px 0;
            width: 700px;
            max-width: 100%;
            p {
                font-size: 1.9rem;
                font-weight: 300;
                line-height: 2.2rem;
            }
        }
    }
    .branding {
        background: #fafafa;
        padding: 0 15px;
        .branding-item {
            margin-top: 64px;
            margin-bottom: 64px;
            p {
                font-size: 20px;
                font-weight: 300;
            }
        }
    }
    .landing-sec {
        box-sizing: border-box;
        min-height: 550px;
        padding: 24px;
        background-position: center top;
        background-size: cover;
        display: flex;
        .container {
            flex-direction: column;
            justify-content: center;
            display: flex;
            .items-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                p,
                h4 {
                    font-family: 'Josefin Sans', sans-serif;
                    -webkit-text-stroke: 1px rgb(207, 201, 201);
                    color: white;
                    text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                }

                h4 { font-size: 35px; }
                p { font-size: 22px; }
            }
        }
        .container-right {
            flex-direction: row;
            justify-content: flex-end;
        }
    }
    .sec-right {
        justify-content: end;
    }
    .landing_image_block {
        position: relative;
        img {
            display: block;
            float: right;
        }
        div {
            position: absolute;
            background: rgb(46, 9, 9);
            width: 400px;
            top: 170px;
            left: 0;
            color: #FFF;
            padding: 15px;
            h1 {
                font-size: 40px;
            }
        }
    }
}