@import './../../styles/_mixings';

.Admin {
    overflow: hidden;
    @include headerMargin();

    .add-course-box {
        margin-top: 20px;
    }

    .grid-container {
        flex-grow: 1;
        margin-top: 20px;
    }
}