@import './../../styles/_mixings';
.PreviewCourse {
    overflow: hidden;
    @include headerMargin();
    .top-box {
        background-color: hsl(0, 0%, 7%);
        background-position: center top;
        background-size: cover;
        min-height: 360px;
        padding: 96px 16px 48px 16px;
        text-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        display: flex;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        h2 {
            height: 50px;
            line-height: 100%;
            font-weight: 700;
        }
        .subtitle {
            text-align: center;
            margin: 68px 0;
            width: 700px;
            max-width: 100%;
            p {
                font-size: 1.9rem;
                font-weight: 400;
                line-height: 2.2rem;
            }
        }
        h2,
        p {
            font-family: 'Josefin Sans', sans-serif;
            -webkit-text-stroke: 1px rgb(207, 201, 201);
            color: white;
            text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        }
    }
    .block-info {
        text-align: left;
        padding: 30px 15px 0 15px;
        h4 {
            font-size: 2.2rem;
            margin-bottom: 1rem;
            font-weight: bold;
            line-height: 1.1;
            color: #292929;
        }
        p {
            margin-bottom: 2rem;
            line-height: 1.7;
            font-size: 1.25rem;
        }
    }
}