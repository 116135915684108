@import './breakpoints';

// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
} 

// Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
} 

// Large devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
} 

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

// Custom devices
@mixin rwd($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
 }


 @mixin headerMargin() {
    @media screen and (max-width: $screen-md-min) {
        margin-top: 56px;
    }
    @media screen and (min-width: $screen-xl-min) {
        margin-top: 64px;
    }
}