.Question {
  height: 90%;

  ._question-block {
    margin: 1.5% 4.5%;
    border: 1px solid #dbe2e8;
    box-shadow: 0 0 10px 0 rgba(46, 60, 73, .2);
    min-height: 400px;
    padding: 3%;
    font-weight: 300;
    font-size: 22px;
    text-align: left;

    div {
        width: 100%;
        height: 100%;
    }

    .block {
      min-height: 50px;
      margin-bottom: 25px;
    }
  }

  .submit {
    padding: 0 3.7%;

    button {
      min-width: 200px;
      text-transform: capitalize;
    }
  }

  .hint {
    display: block;
    padding: 10px;
  }
}
