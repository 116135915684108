@import './../../styles/_mixings';

.Home {
    overflow: hidden;
    @include headerMargin();

    .course-box {
        border-radius: 0px;
        width: 100%;

        .course-info {
            margin: 0;
            width: 100%;

            @include md {
                height: 230px;
            }

            img {
                display: block;
                height: 100%;
                width: 100%;
            }

            .couse-detail {
                @include md {
                    padding: 30px 40px;
                }
            }
        }
    }

    .block-info, .similar-info  {
        text-align: left;
        
        p {
            text-transform: capitalize;
            line-height: 1.7;
            font-size: 1.2rem;
        }

        h4 {
            margin-top: 2rem;
            font-size: 1.5rem;
            margin-bottom: 1rem;
            font-weight: bold;
            line-height: 1.1;
            color: #292929;
            padding: 0 10px;

            @include md {
                padding: 0;
            }
        }
    }

    .similar-info {
        @include md {
            margin-top: 40px;
        }
    }
}